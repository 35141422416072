var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user"},[_c('section',{staticClass:"personalArea__header container"},[_c('div',{staticClass:"personalArea__header-title_container"},[_c('router-link',{staticClass:"personalArea-link",class:{
          active:
            _vm.isChildRouteActive('userOrders') || _vm.isDefaultRoute('userOrders'),
          disabled: _vm.isChildRouteActive('logout'),
        },attrs:{"to":{ name: 'userOrders' },"disabled":_vm.isChildRouteActive('logout')}},[_vm._v("Заказы")]),_c('router-link',{staticClass:"personalArea-link",class:{
          active: _vm.isChildRouteActive('userFavorites'),
          disabled: _vm.isChildRouteActive('logout'),
        },attrs:{"to":{ name: 'userFavorites' },"disabled":_vm.isChildRouteActive('logout')}},[_vm._v("Избранное")]),_c('a',{staticClass:"personalArea-link",class:{ active: _vm.isChildRouteActive('logout') },on:{"click":_vm.logout}},[_vm._v("Выход")])],1),_c('div',{staticClass:"personalArea__heade_under-line"})]),_c('div',{staticClass:"user-routing"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('FormCard')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }