var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",style:({ cursor: _vm.cursorStyle }),on:{"mouseenter":_vm.showCursor,"mouseleave":_vm.hideCursor}},[_c('div',{class:[
    'g-cursor',
    { 'g-cursor_hover': _vm.hover },
    { 'g-cursor_hide': _vm.hideCursor },
  ]},[_c('div',{staticClass:"g-cursor__circle",style:(_vm.cursorCircle)}),_c('div',{ref:"point",staticClass:"g-cursor__point",style:(_vm.cursorPoint)})]),_c('div',{ref:"swiper",staticClass:"swiper mySwiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.bannerPage),function(slide){return _c('div',{key:slide.id,staticClass:"swiper-slide",style:({
        background: 'url(' + slide.photo + ') center center / cover',
      })},[_c('div',{staticClass:"container container-a_p"},[_c('div',{staticClass:"slide-card__container"},[_c('div',{staticClass:"slide-card__subtitle",staticStyle:{"text-transform":"uppercase"},style:({
              backgroundColor:
                slide.status === 'New'
                  ? '#ED4546'
                  : slide.status === 'Hit'
                    ? '#192965'
                    : 'Green',
            })},[_vm._v(" "+_vm._s(slide.status === "New" ? "Новинка" : slide.status === "Hit" ? "Хит" : "Новости")+" ")]),_c('div',{staticClass:"slide-card__descriptor"},[_vm._v("Коллекция")]),_c('div',{staticClass:"slide-card__title",class:{ 'news-status': !slide.status }},[_vm._v(_vm._s(slide.title))]),_c('div',{staticClass:"slide-card__button-container"},[_c('a',{staticClass:"slide-card__button",on:{"click":function($event){!slide.status ? _vm.getNewsDetails(slide.id) : _vm.getProductDetails(slide.id)}}},[_vm._v(" Смотреть ")])])])])])}),0),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-pagination"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }